<template>
  <div class="container-fluid px-0">
    <div class="page-header mt-0">
      <img
        src="@/assets/img/a.png"
        alt="CubeColony SMP Banner"
        class="shadow-md w-100 img-fluid"
      />
    </div>
    <div class="card card-body mx-3 mx-md-4 mt-n7 mb-5">
      <div class="row gx-4">
        <div class="col-12 text-center">
          <div class="avatar avatar-xl position-relative">
            <img
              src="https://mc-heads.net/avatar/benj"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
          <span class="mask bg-transparent opacity-6"></span>
        </div>
        <div class="col-12 my-auto text-center mt-3 position-relative" style="z-index: 1;">
          <div>
            <h4 class="mb-1">Transaction Successful</h4>
            <router-link to="/dashboard">
              <Icon class="mt-0 h2" icon="mdi:arrow-back-circle" color="#4BB543" />
            </router-link>
            <p class="mb-0 font-weight-normal text-md">Back to Dashboard</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

import MiniCards from "./components/MiniCards.vue";
import { Icon } from '@iconify/vue';

export default {
  name: "success",
  components: {
    MiniCards,
    Icon
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  async mounted() {},
};
</script>
