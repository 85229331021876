<template>
  <div class="page-header mt-0">
    <img
      src="@/assets/img/a.png"
      alt="profile_image"
      class="shadow-md w-100 img-fluid"
    >
    <div class="col-12 d-flex h-100 align-text-bottom justify-content-end card-img-overlay card-inverse">
      <div class="mt-auto mb-2">
        <span >
          <a href="https://wrldmind.com"><Icon class="mt-0 h2" icon="mdi:minecraft" color="#AAAAAA" /></a>
        </span>&nbsp;
        <span>
          <a href="https://wrldmind.com"><Icon class="h2" icon="skill-icons:discord" /></a>
        </span>
      </div>

    </div>
  </div>
  <div class="card card-body mx-3 mx-md-4 mt-n4">
    <div class="row gx-4">
      <div class="col-auto">
        <div class="avatar avatar-xl position-relative">
          <img
            :src="minecraft.head_2d"
            alt="profile_image"
            class="shadow-sm w-100 border-radius-lg"
          />
        </div>
        <span class="mask bg-transparent opacity-6"></span>
      </div>
      <div class="col-auto my-auto">
        <div class="">
          <p class="mb-0 font-weight-normal text-xs">Logged in as</p>
          <h5 class="mb-0">{{ username }}</h5>
          <h5 class="mb-1 text-xs text-muted">{{ wallet }}</h5>
          <h5 class="mb-1 text-xs text-muted">Minecraft Username: {{ minecraft.minecraft_username === 'Player' ? "Unknown" : minecraft.minecraft_username}}</h5>
          <!--            <p class="mb-0 font-weight-normal text-sm">-->
          <!--              Owner:-->
          <!--              <a-->
          <!--                v-bind:href="-->
          <!--                  'https://etherscan.io/address/' + selectedCollection.owner-->
          <!--                "-->
          <!--                class="text-info"-->
          <!--              >-->
          <!--                {{ getEllipsisTxt(selectedCollection.owner, 5) }}-->
          <!--              </a>-->
          <!--            </p>-->
        </div>
      </div>
      <div
        class="col-lg-8 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"
      >
        <div class="nav-wrapper position-relative end-0">
          <ul class="p-1 nav nav-pills nav-fill" role="tablist">
            <li class="nav-item">
              <h5 class="mb-1">
                {{ currencies }}
              </h5>
              <p class="mb-0 font-weight-normal text-sm mb-1">Cubes</p>
            </li>
            <li class="nav-item">
              <h5 class="mb-0">
                {{ items }}
              </h5>
              <p class="mb-0 font-weight-normal text-sm mb-1">Lootboxes</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniCards from "./components/MiniCards.vue";
import { Icon } from '@iconify/vue';

export default {
  name: "site-header",
  components: {
    MiniCards,
    Icon
  },
  data() {
    return {
      test: "",
    };
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    wallet: {
      type: String,
      required: true,
    },
    player: {
      type: Object,
      required: true
    },
    minecraft: {
      type: Object,
      required: true
    },
    items: {
      type: String,
      required: true
    },
    currencies: {
      type: String,
      required: true
    }
  },
  watch: {
    username: function(newVal, oldVal) { // watch it
      this.test = this.username
    },
  },
  mounted() {
  }
};
</script>
