<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }}, made with
            <i class="fa fa-heart"></i> by
            <a
              href="http://www.devtechsolutions.us"
              class="font-weight-bold"
              target="_blank"
              >DevTech Solutions LLC company.</a
            >
          </div>
        </div>
        <div class="col-lg-6">
          <ul
            class="nav nav-footer justify-content-center justify-content-lg-end"
          >
            <li class="nav-item">
              <a
                href="https://twitter.com/proxcity_xyz"
                class="nav-link text-muted"
                target="_blank"
                >Twitter</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://discord.gg/HDKa4FRDFG"
                class="nav-link text-muted"
                target="_blank"
                >Discord</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.youtube.com/@devtechsolutions2021"
                class="nav-link text-muted"
                target="_blank"
                >Youtube</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
