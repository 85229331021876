<template>
  <div class="container-fluid px-0">
    <div class="page-header mt-0">
      <img
        src="@/assets/img/a.png"
        alt="profile_image"
        class="shadow-md w-100 img-fluid"
      />
    </div>
    <div class="card card-body mx-3 mx-md-4 mt-n7 mb-5">
      <div class="row gx-4">
        <div class="col-12 text-center">
          <div class="avatar avatar-xl position-relative">
            <img
              src="https://mc-heads.net/avatar/benj"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
          <span class="mask bg-transparent opacity-6"></span>
        </div>
        <div class="col-12 my-auto text-center mt-3">
          <div class="">
            <template v-if="success">
              <h3 class="mb-1 text-success">Authenticated</h3>
              <p class="mb-0 font-weight-normal text-lg">You can close this page now.</p>
            </template>
            <template v-else>
              <p class="mb-0 font-weight-normal text-lg">Please wait</p>
              <h3 class="mb-1">Authenticating...</h3>
              <div class="spinner-border text-danger ml-auto" role="status" aria-hidden="true"></div>
            </template>
            <!--            <p class="mb-0 font-weight-normal text-sm">-->
            <!--              Owner:-->
            <!--              <a-->
            <!--                v-bind:href="-->
            <!--                  'https://etherscan.io/address/' + selectedCollection.owner-->
            <!--                "-->
            <!--                class="text-info"-->
            <!--              >-->
            <!--                {{ getEllipsisTxt(selectedCollection.owner, 5) }}-->
            <!--              </a>-->
            <!--            </p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import MiniCards from "./components/MiniCards.vue";
import { Icon } from '@iconify/vue';

export default {
  name: "auth",
  components: {
    MiniCards,
    Icon
  },
  data() {
    return {
      success: false,
      error: false
    };
  },
  computed: {},
  methods: {},
  async mounted() {
    this.$store.state.showFooter = false
    const data = this.$route.query.data.split("!");
    debugger;
    const code = data[0];
    const url = data[1];
    let body = {
      "code": code,
    };
    const hashWithoutLeadingHash = this.$route.hash.replace(/^#/, "")
    const keyValuePairs = hashWithoutLeadingHash.split('&');
    keyValuePairs.forEach(keyValuePair => {
      const [key, value] = keyValuePair.split('=');
      body[key] = value
    });
    let options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      url: 'https://api.cubecolony.net/v1/metafab/authenticate'
    };
    fetch(`https://api.cubecolony.net/v1/metafab/authenticate`, options)
      .then(response => {
        if (!response.ok) {
          alert("Authentication Failed, please try again. If issue continues, reach out to support via discord.")
        } else {
          this.success = true;
        }
      })
      .then(response => {console.log("SUCCESS")})
      .catch(err => {console.error(err); this.success = false;});
  },
};
</script>
