const purchaseItems = {
  "ranks": [
    {
      "id": "0001",
      "uniqueName": "viprank",
      "name": "VIP Rank",
      "description": "On our Minecraft SMP server, we offer different ranks that provide a range of perks and benefits to enhance your gameplay experience. As a rank holder, you'll gain access to exclusive areas, extra gems, and in-game currency to boost your balance. You'll also have the ability to claim land, create auction house listings, and add players to your clan, giving you an edge in the server's economy. With priority queue access, you'll skip the waitlist and get right into the action. By purchasing a rank, you'll be able to take your Minecraft gameplay to the next level and enjoy a more immersive and rewarding experience.",
      "img": "rank-vip.png",
      "originalPrice": 5.99,
      "discountedPrice": 5.28,
      "priceId": "price_1MwIXUKGtL2OhGM1vhqakkTy",
      "generalItems": [
        "VIP prefix in front of your name",
        "+100 gems",
        "+$2,500 balance",
        "Priority queue level 1 (skip the server waitlist!)",
        "Access to set 2 homes (/sethome)",
        "Access to claim 1000 blocks of land (/claim)",
        "Access to create 5 auction house listings (/ah)",
        "Ability to add 7 players to clan (/clan)",
        "Access to the EXCLUSIVE CLUB at spawn!",
        "Infinite VIP Kit! (/kit vip)"
      ],
      "kitItems": [
        "VIP Helmet",
        "VIP Chestplate",
        "VIP Leggings",
        "VIP Boots",
        "VIP Sword",
        "VIP Pickaxe",
        "VIP Axe",
        "VIP Shovel",
        "VIP Hoe",
        "64 Steak",
        "8 Golden Apples",
        "64 bottles o’ enchanting",
        "1 totem of undying",
        "8 diamonds",
        "VIP Fishing Rod"
      ],
      "image": "vip-items.png"
    },
    {
      "id": "0002",
      "uniqueName": "mvprank",
      "name": "MVP Rank",
      "description": "On our Minecraft SMP server, we offer different ranks that provide a range of perks and benefits to enhance your gameplay experience. As a rank holder, you'll gain access to exclusive areas, extra gems, and in-game currency to boost your balance. You'll also have the ability to claim land, create auction house listings, and add players to your clan, giving you an edge in the server's economy. With priority queue access, you'll skip the waitlist and get right into the action. By purchasing a rank, you'll be able to take your Minecraft gameplay to the next level and enjoy a more immersive and rewarding experience.",
      "img": "rank-mvp.png",
      "originalPrice": 12.99,
      "discountedPrice": 11.46,
      "priceId": "price_1MwIXIKGtL2OhGM1272b7xbY",
      "generalItems": [
        "MVP prefix in front of your name",
        "+250 gems",
        "+$5,000 balance",
        "Priority queue level 2 (skip the server waitlist!)",
        "Access to set 3 homes (/sethome)",
        "Access to claim 1500 blocks of land (/claim)",
        "Access to create 10 auction house listings (/ah)",
        "Ability to have 9 clan members (/clan)",
        "Access to enderchest anywhere (/enderchest)",
        "Access to the EXCLUSIVE CLUB at spawn!",
        "Infinite MVP Kit! (/kit mvp)"
      ],
      "kitItems": [
        "MVP Helmet",
        "MVP Chestplate",
        "MVP Leggings",
        "MVP Boots",
        "MVP Sword",
        "MVP Pickaxe",
        "MVP Axe",
        "MVP Shovel",
        "MVP Hoe",
        "64 Steak",
        "32 Golden Apples",
        "2 stacks of bottles o’ enchanting",
        "2 totems of undying",
        "2 enchanted Golden Apples",
        "24 diamonds",
        "MVP Fishing Rod",
        "MVP Bow"
      ],
      "image": "mvp-items.png"
    },
    {
      "id": "0003",
      "uniqueName": "legendrank",
      "name": "Legend Rank",
      "description": "On our Minecraft SMP server, we offer different ranks that provide a range of perks and benefits to enhance your gameplay experience. As a rank holder, you'll gain access to exclusive areas, extra gems, and in-game currency to boost your balance. You'll also have the ability to claim land, create auction house listings, and add players to your clan, giving you an edge in the server's economy. With priority queue access, you'll skip the waitlist and get right into the action. By purchasing a rank, you'll be able to take your Minecraft gameplay to the next level and enjoy a more immersive and rewarding experience.",
      "img": "rank-legend.png",
      "originalPrice": 29.99,
      "discountedPrice": 26.45,
      "priceId": "price_1MwIXAKGtL2OhGM14yIxmCMD",
      "generalItems": [
        "LEGEND prefix in front of your name",
        "+500 gems",
        "+$10,000 balance",
        "Priority queue level 3 (skip the server waitlist!)",
        "Access to set 4 homes (/sethome)",
        "Access to claim 2000 blocks of land (/claim)",
        "Access to create 15 auction house listings (/ah)",
        "Ability to have 11 clan members (/clan)",
        "Access to enderchest anywhere (/enderchest)",
        "Access to the EXCLUSIVE CLUB at spawn!",
        "Infinite LEGEND Kit! (/kit legend)"
      ],
      "kitItems": [
        "LEGEND Helmet",
        "LEGEND Chestplate",
        "LEGEND Leggings",
        "LEGEND Boots",
        "LEGEND Sword",
        "LEGEND Pickaxe",
        "LEGEND Axe",
        "LEGEND Shovel",
        "LEGEND Hoe",
        "64 Golden Carrots",
        "48 Golden Apples",
        "3 stacks of bottles o’ enchanting",
        "3 totems of undying",
        "6 enchanted Golden Apples",
        "32 diamonds",
        "4 netherite ingots",
        "LEGEND Fishing Rod",
        "LEGEND Bow",
        "LEGEND Trident"
      ],
      "image": "legend-items.png"
    },
    {
      "id": "0004",
      "name": "Ultra Rank",
      "uniqueName": "ultrarank",
      "description": "On our Minecraft SMP server, we offer different ranks that provide a range of perks and benefits to enhance your gameplay experience. As a rank holder, you'll gain access to exclusive areas, extra gems, and in-game currency to boost your balance. You'll also have the ability to claim land, create auction house listings, and add players to your clan, giving you an edge in the server's economy. With priority queue access, you'll skip the waitlist and get right into the action. By purchasing a rank, you'll be able to take your Minecraft gameplay to the next level and enjoy a more immersive and rewarding experience.",
      "img": "rank-ultra.png",
      "originalPrice": 59.99,
      "discountedPrice": 43.79,
      "priceId": "price_1MwIX4KGtL2OhGM1st9gywLM",
      "generalItems": [
        "ULTRA prefix in front of your name",
        "+1000 gems",
        "+20,000 balance",
        "Priority queue level 4 (skip the server waitlist!)",
        "Access to set 5 homes (/sethome)",
        "Access to claim 2500 blocks of land (/claim)",
        "Access to create 20 auction house listings (/ah)",
        "Ability to have 13 clan members (/clan)",
        "Access to create 1 playerwarp (/playerwarp)",
        "Access to enderchest anywhere (/enderchest)",
        "Access to the EXCLUSIVE CLUB at spawn!",
        "Infinite ULTRA Kit! (/kit ultra)"
      ],
      "kitItems": [
        "ULTRA prefix in front of your name",
        "+1000 gems",
        "+$20,000 balance",
        "Priority queue level 4 (skip the server waitlist!)",
        "Access to set 5 homes (/sethome)",
        "Access to claim 2500 blocks of land (/claim)",
        "Access to create 20 auction house listings (/ah)",
        "Ability to have 13 clan members (/clan)",
        "Access to create 1 playerwarp (/playerwarp)",
        "Access to enderchest anywhere (/enderchest)",
        "Access to the EXCLUSIVE CLUB at spawn!",
        "Infinite ULTRA Kit! (/kit ultra)",
        "ULTRA Helmet",
        "ULTRA Chestplate",
        "ULTRA Leggings",
        "ULTRA Boots",
        "ULTRA Sword",
        "ULTRA Pickaxe",
        "ULTRA Axe",
        "ULTRA Shovel",
        "ULTRA Hoe",
        "64 Golden Carrots",
        "64 Golden Apples",
        "4 stacks of bottles o’ enchanting",
        "4 totems of undying",
        "12 enchanted Golden Apples",
        "48 diamonds",
        "8 netherite ingots",
        "2 shulker boxes",
        "ULTRA Fishing Rod",
        "ULTRA Bow",
        "ULTRA Trident",
        "ULTRA Elytra"
      ],
      "image": "ultra-items.png"
    },
    {
      "id": "0005",
      "uniqueName": "godrank",
      "name": "God Rank",
      "description": "On our Minecraft SMP server, we offer different ranks that provide a range of perks and benefits to enhance your gameplay experience. As a rank holder, you'll gain access to exclusive areas, extra gems, and in-game currency to boost your balance. You'll also have the ability to claim land, create auction house listings, and add players to your clan, giving you an edge in the server's economy. With priority queue access, you'll skip the waitlist and get right into the action. By purchasing a rank, you'll be able to take your Minecraft gameplay to the next level and enjoy a more immersive and rewarding experience.",
      "img": "rank-god.png",
      "originalPrice": 89.99,
      "discountedPrice": 68.19,
      "priceId": "price_1MwIWzKGtL2OhGM1TsNvxHoe",
      "generalItems": [
        "GOD prefix in front of your name",
        "+1600 gems",
        "+$30,000 balance",
        "Access to Infinite Flight (/fly)",
        "Priority queue level 5 (skip the server waitlist!)",
        "Access to claim 3000 blocks of land (/claim)",
        "Access to set 7 homes (/sethome)",
        "Access to create 25 auction house listings (/ah)",
        "Ability to have 15 clan members (/clan)",
        "Access to create 2 playerwarps (/playerwarp)",
        "Access to enderchest anywhere (/enderchest)",
        "Access to the EXCLUSIVE CLUB at spawn!",
        "Infinite GOD Kit! (/kit god)"
      ],
      "kitItems": [
        "GOD Helmet",
        "GOD Chestplate",
        "GOD Leggings",
        "GOD Boots",
        "GOD Sword",
        "GOD Pickaxe",
        "GOD Axe",
        "GOD Shovel",
        "GOD Hoe",
        "64 Golden Carrots",
        "96 stacks of Golden Apples",
        "5 stacks of bottles o’ enchanting",
        "5 totems of undying",
        "24 enchanted Golden Apples",
        "64 diamonds",
        "12 netherite ingots",
        "4 shulker boxes",
        "GOD Fishing Rod",
        "GOD Bow",
        "GOD Riptide Trident",
        "GOD Elytra",
        "GOD Thunder Trident"
      ],
      "image": "god-items.png"
    }
  ],
  "keys": [
    {
      "id": "0001",
      "uniqueName": "mythicrank",
      "name": "Mythic Key",
      "description": "On our Minecraft SMP server, Crate keys are one-time-use items that allow you to claim rewards from various crates on the server. Simply use the key in-game and the reward will be automatically added to your inventory.",
      "img": "key-mythic.png",
      "originalPrice": 2.59,
      "discountedPrice": 1.94,
      "priceId": "price_1MwIWuKGtL2OhGM10Etq1N4g",
      "generalItems": [],
      "kitItems": [
        "Mythic Helmet",
        "Mythic Chestplate",
        "Mythic Leggings",
        "Mythic Boots",
        "Mythic Sword",
        "Mythic Pickaxe (Fortune)",
        "Mythic Pickaxe (Silk Touch)",
        "Mythic Shovel",
        "Mythic Axe",
        "Mythic Hoe",
        "Mythic Bow",
        "Mythic Crossbow",
        "8 Blocks of Coal",
        "8 Blocks of Iron",
        "8 Blocks of Gold",
        "8 Blocks of Redstone",
        "8 Blocks of Lapis",
        "8 Blocks of Diamond",
        "8 Blocks of Emerald",
        "8 Golden Apples",
        "1 Totem of Undying",
        "16 Obsidian",
        "12 End Crystals",
        "Strength Potion",
        "$5,000 Server Balance",
        "$10,000 Server Balance",
        "$25,000 Server Balance"
      ],
      "image": "mythic-items.png"
    },
    {
      "id": "0001",
      "uniqueName": "threemythicrank",
      "name": "3x Mythic Key",
      "description": "On our Minecraft SMP server, Crate keys are one-time-use items that allow you to claim rewards from various crates on the server. Simply use the key in-game and the reward will be automatically added to your inventory.",
      "img": "key-mythic-3.png",
      "originalPrice": 6.50,
      "discountedPrice": 4.86,
      "priceId": "price_1MwIWpKGtL2OhGM1avFhXydU",
      "generalItems": [],
      "kitItems": [
        "Mythic Helmet",
        "Mythic Chestplate",
        "Mythic Leggings",
        "Mythic Boots",
        "Mythic Sword",
        "Mythic Pickaxe (Fortune)",
        "Mythic Pickaxe (Silk Touch)",
        "Mythic Shovel",
        "Mythic Axe",
        "Mythic Hoe",
        "Mythic Bow",
        "Mythic Crossbow",
        "8 Blocks of Coal",
        "8 Blocks of Iron",
        "8 Blocks of Gold",
        "8 Blocks of Redstone",
        "8 Blocks of Lapis",
        "8 Blocks of Diamond",
        "8 Blocks of Emerald",
        "8 Golden Apples",
        "1 Totem of Undying",
        "16 Obsidian",
        "12 End Crystals",
        "Strength Potion",
        "$5,000 Server Balance",
        "$10,000 Server Balance",
        "$25,000 Server Balance"
      ],
      "image": "mythic-items.png"
    },
    {
      "id": "0002",
      "uniqueName": "cosmicrank",
      "name": "Cosmic Key",
      "description": "On our Minecraft SMP server, Crate keys are one-time-use items that allow you to claim rewards from various crates on the server. Simply use the key in-game and the reward will be automatically added to your inventory.",
      "img": "key-cosmic.png",
      "originalPrice": 4.50,
      "discountedPrice": 2.89,
      "priceId": "price_1MwIWZKGtL2OhGM1yvC7Bo9b",
      "generalItems": [],
      "kitItems": [
        "Cosmic Helmet",
        "Cosmic Chestplate",
        "Cosmic Leggings",
        "Cosmic Boots",
        "Cosmic Sword",
        "Cosmic Pickaxe (Fortune)",
        "Cosmic Pickaxe (Silk Touch)",
        "Cosmic Shovel",
        "Cosmic Axe",
        "Cosmic Hoe",
        "Cosmic Bow",
        "Cosmic Crossbow",
        "12 Blocks of Coal",
        "12 Blocks of Iron",
        "12 Blocks of Gold",
        "12 Blocks of Redstone",
        "12 Blocks of Diamond",
        "12 Blocks of Emerald",
        "2 Blocks of Netherite",
        "16 Golden Apples",
        "2 Totem of Undying",
        "32 Obsidian",
        "24 End Crystals",
        "1 Enchanted Golden Apple",
        "$50,000 Server Balance",
        "$75,000 Server Balance",
        "$100,000 Server Balance"
      ],
      "image": "cosmic-items.png"
    },
    {
      "id": "0002",
      "uniqueName": "threecosmicrank",
      "name": "3x Cosmic Key",
      "description": "On our Minecraft SMP server, Crate keys are one-time-use items that allow you to claim rewards from various crates on the server. Simply use the key in-game and the reward will be automatically added to your inventory.",
      "img": "key-cosmic-3.png",
      "originalPrice": 11.50,
      "discountedPrice": 7.76,
      "priceId": "price_1MwIWRKGtL2OhGM1C8J6bCTU",
      "generalItems": [],
      "kitItems": [
        "Cosmic Helmet",
        "Cosmic Chestplate",
        "Cosmic Leggings",
        "Cosmic Boots",
        "Cosmic Sword",
        "Cosmic Pickaxe (Fortune)",
        "Cosmic Pickaxe (Silk Touch)",
        "Cosmic Shovel",
        "Cosmic Axe",
        "Cosmic Hoe",
        "Cosmic Bow",
        "Cosmic Crossbow",
        "12 Blocks of Coal",
        "12 Blocks of Iron",
        "12 Blocks of Gold",
        "12 Blocks of Redstone",
        "12 Blocks of Diamond",
        "12 Blocks of Emerald",
        "2 Blocks of Netherite",
        "16 Golden Apples",
        "2 Totem of Undying",
        "32 Obsidian",
        "24 End Crystals",
        "1 Enchanted Golden Apple",
        "$50,000 Server Balance",
        "$75,000 Server Balance",
        "$100,000 Server Balance"
      ],
      "image": "cosmic-items.png"
    },
    {
      "id": "0003",
      "uniqueName": "celestialrank",
      "name": "Celestial Key",
      "description": "On our Minecraft SMP server, Crate keys are one-time-use items that allow you to claim rewards from various crates on the server. Simply use the key in-game and the reward will be automatically added to your inventory.",
      "img": "key-celestial.png",
      "originalPrice": 7.50,
      "discountedPrice": 5.84,
      "priceId": "price_1MwIWMKGtL2OhGM1u0clvm43",
      "generalItems": [],
      "kitItems": [
        "Celestial Helmet",
        "Celestial Chestplate",
        "Celestial Leggings",
        "Celestial Boots",
        "Celestial Sword",
        "Celestial Pickaxe (Fortune)",
        "Celestial Pickaxe (Silk Touch)",
        "Celestial Shovel",
        "Celestial Axe",
        "Celestial Hoe",
        "Celestial Bow",
        "Celestial Crossbow",
        "16 Blocks of Coal",
        "16 Blocks of Iron",
        "16 Blocks of Gold",
        "16 Blocks of Redstone",
        "16 Blocks of Diamond",
        "16 Blocks of Emerald",
        "4 Blocks of Netherite",
        "32 Golden Apples",
        "4 Totem of Undying",
        "64 Obsidian",
        "48 End Crystals",
        "3 Enchanted Golden Apples",
        "$150,000 Server Balance",
        "$250,000 Server Balance",
        "$500,000 Server Balance"
      ],
      "image": "celestial-items.png"
    },
    {
      "id": "0003",
      "uniqueName": "threecelestialrank",
      "name": "3x Celestial Key",
      "description": "On our Minecraft SMP server, Crate keys are one-time-use items that allow you to claim rewards from various crates on the server. Simply use the key in-game and the reward will be automatically added to your inventory.",
      "img": "key-celestial-3.png",
      "originalPrice": 22.50,
      "discountedPrice": 16.54,
      "priceId": "price_1MwIWIKGtL2OhGM18eQj0Yy2",
      "generalItems": [],
      "kitItems": [
        "Celestial Helmet",
        "Celestial Chestplate",
        "Celestial Leggings",
        "Celestial Boots",
        "Celestial Sword",
        "Celestial Pickaxe (Fortune)",
        "Celestial Pickaxe (Silk Touch)",
        "Celestial Shovel",
        "Celestial Axe",
        "Celestial Hoe",
        "Celestial Bow",
        "Celestial Crossbow",
        "16 Blocks of Coal",
        "16 Blocks of Iron",
        "16 Blocks of Gold",
        "16 Blocks of Redstone",
        "16 Blocks of Diamond",
        "16 Blocks of Emerald",
        "4 Blocks of Netherite",
        "32 Golden Apples",
        "4 Totem of Undying",
        "64 Obsidian",
        "48 End Crystals",
        "3 Enchanted Golden Apples",
        "$150,000 Server Balance",
        "$250,000 Server Balance",
        "$500,000 Server Balance"
      ],
      "image": "celestial-items.png"
    }
  ],
  "currency": [
    {
      "id": "0001",
      "uniqueName": "five",
      "name": "500 Cubes",
      "description": "",
      "img": "currency-500.png",
      "originalPrice": 5.99,
      "discountedPrice": 5.28,
      "priceId": "price_1MzBfLKGtL2OhGM1cnnrLq2w",
      "generalItems": [],
      "kitItems": [],
      "image": ""
    },
    {
      "id": "0002",
      "uniqueName": "twelve",
      "name": "1200 Cubes",
      "description": "",
      "img": "currency-1200.png",
      "originalPrice": 12.99,
      "discountedPrice": 11.46,
      "priceId": "price_1MwIW7KGtL2OhGM1DwOkkIDK",
      "generalItems": [],
      "kitItems": [],
      "image": ""
    },
    {
      "id": "0003",
      "uniqueName": "twentyfive",
      "name": "2500 Cubes",
      "description": "",
      "img": "currency-2500.png",
      "originalPrice": 25.99,
      "discountedPrice": 22.92,
      "priceId": "price_1MwIW3KGtL2OhGM1DicTe7ow",
      "generalItems": [],
      "kitItems": [],
      "image": ""
    },
    {
      "id": "0004",
      "uniqueName": "sixty",
      "name": "6000 Cubes",
      "description": "",
      "img": "currency-6000.png",
      "originalPrice": 54.99,
      "discountedPrice": 48.55,
      "priceId": "price_1MwIVwKGtL2OhGM17UdtepL3",
      "generalItems": [],
      "kitItems": [],
      "image": ""
    }
  ]
}
export default purchaseItems;