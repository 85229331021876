<template>
  <div class="container-fluid px-0">
    <site-header :username="this.player.username" :wallet="player.wallet.address" :player="player" :minecraft="minecraftPlayerDetails" :items="lootBoxes" :currencies="cubes"></site-header>
  </div>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
        </div>
        <div class="row mt-4">
            <div class="col-sm-4 col-12 mb-2 text-center mt-2">
              <router-link to="/category/ranks">
                <div class="card bg-success-soft" style="background-image: linear-gradient(50deg,#cc1228,#8b005a);">
                  <div class="row mt-4">
                    <div class="col-6 mt-6">
                      <img
                        src="@/assets/img/game/rank-ultra.png"
                        class="card-img-top align-bottom img-fluid"
                        alt="gold pass"
                        style="width: 70%; height: 100%"
                      />
                    </div>
                    <div class="col-sm-6 col-6 mb-2 text-center">
                      <h3 class="display-6 text-bolder text-white">Ranks</h3>
                    </div>
                  </div>

                  <div class="card-body"></div>
                </div>
              </router-link>
            </div>
          <div class="col-sm-4 col-12 mb-2 text-center mt-2">
            <router-link to="/category/keys">
              <div class="card bg-success-soft" style="background-image: linear-gradient(50deg,#7e179b,#ff00bc);">
                <div class="row mt-4">
                  <div class="col-6 mt-6">
                    <img
                      src="@/assets/img/game/key-mythic-3.png"
                      class="align-bottom img-fluid"
                      alt="key"
                      style="width: 70%; height: 100%"
                    />
                  </div>
                  <div class="col-sm-6 col-6 mb-2 text-center">
                    <h3 class="display-6 text-bolder text-white">Crate Keys</h3>
                  </div>
                </div>

                <div class="card-body"></div>
              </div>
            </router-link>
          </div>
          <div class="col-sm-4 col-12 mb-2 text-center mt-2">
            <router-link to="/category/currency">
              <div class="card bg-success-soft" style="background-image: linear-gradient(50deg,#091f38,rgb(2,167,255));">
                <div class="row mt-4">
                  <div class="col-6 mt-6">
                    <img
                      src="@/assets/img/game/currency-500.png"
                      class="align-bottom img-fluid"
                      alt="gold pass"
                      style="width: 70%; height: 100% "
                    />
                  </div>
                  <div class="col-sm-6 col-6 mb-2 text-center">
                    <h3 class="display-6 text-bolder text-white">Cubes</h3>
                  </div>
                </div>

                <div class="card-body"></div>
              </div>
            </router-link>
          </div>
          <div class="col-12 text-center mt-4">
            <hr class="mt-3 mb-1 horizontal dark">
          </div>
        </div>
        <div class="row mt-4 mb-4">
          <!-- Rank Inventory-->
          <div class="col-lg-6">
            <div class="card h-100">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-0">Rank Inventory</h6>
                </div>
              </div>
              <div class="p-3 card-body">
                <ul class="list-group list-group-flush list my--3" v-if="Object.keys(items).length !== 0">
                  <li class="px-0 border-0 list-group-item">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <img src="@/assets/img/game/rank-vip.png" alt="Item" style="width: 50%; height: 100%"/>
                      </div>
                      <div class="col">
                        <p class="mb-0 text-xs font-weight-bold">Item:</p>
                        <h6 class="mb-0 text-sm">VIP Rank</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Amount:</p>
                        <h6 class="mb-0 text-sm">{{ items["e1261d6a-09f2-4383-9e75-717f3986d257"]["balances"]["1"] }}</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Transfer:</p>
                        <Icon class="mt-0 h5" icon="mdi:cube-send" color="#091f38" />
                      </div>
                    </div>
                    <hr class="mt-3 mb-1 horizontal dark" />
                  </li>
                  <li class="px-0 border-0 list-group-item">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <img src="@/assets/img/game/rank-mvp.png" alt="Item" style="width: 50%; height: 100%"/>
                      </div>
                      <div class="col">
                        <p class="mb-0 text-xs font-weight-bold">Item:</p>
                        <h6 class="mb-0 text-sm">MVP Rank</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Amount:</p>
                        <h6 class="mb-0 text-sm">{{ items["e1261d6a-09f2-4383-9e75-717f3986d257"]["balances"]["2"] }}</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Transfer:</p>
                        <Icon class="mt-0 h5" icon="mdi:cube-send" color="#091f38" />
                      </div>
                    </div>
                    <hr class="mt-3 mb-1 horizontal dark" />
                  </li>
                  <li class="px-0 border-0 list-group-item">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <img src="@/assets/img/game/rank-legend.png" alt="Item" style="width: 50%; height: 100%"/>
                      </div>
                      <div class="col">
                        <p class="mb-0 text-xs font-weight-bold">Item:</p>
                        <h6 class="mb-0 text-sm">Legend Rank</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Amount:</p>
                        <h6 class="mb-0 text-sm">{{ items["e1261d6a-09f2-4383-9e75-717f3986d257"]["balances"]["3"] }}</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Transfer:</p>
                        <Icon class="mt-0 h5" icon="mdi:cube-send" color="#091f38" />
                      </div>
                    </div>
                    <hr class="mt-3 mb-1 horizontal dark" />
                  </li>
                  <li class="px-0 border-0 list-group-item">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <img src="@/assets/img/game/rank-ultra.png" alt="Item" style="width: 50%; height: 100%"/>
                      </div>
                      <div class="col">
                        <p class="mb-0 text-sm font-weight-bold">Item:</p>
                        <h6 class="mb-0 text-md">Ultra Rank</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-sm font-weight-bold">Amount:</p>
                        <h6 class="mb-0 text-md">{{ items["e1261d6a-09f2-4383-9e75-717f3986d257"]["balances"]["4"] }}</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Transfer:</p>
                        <Icon class="mt-0 h5" icon="mdi:cube-send" color="#091f38" />
                      </div>
                    </div>
                    <hr class="mt-3 mb-1 horizontal dark" />
                  </li>
                  <li class="px-0 border-0 list-group-item">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <img src="@/assets/img/game/rank-god.png" alt="Item" style="width: 50%; height: 100%"/>
                      </div>
                      <div class="col">
                        <p class="mb-0 text-xs font-weight-bold">Item:</p>
                        <h6 class="mb-0 text-sm">God Rank</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Amount:</p>
                        <h6 class="mb-0 text-sm">{{ items["e1261d6a-09f2-4383-9e75-717f3986d257"]["balances"]["5"] }}</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Transfer:</p>
                        <Icon class="mt-0 h5" icon="mdi:cube-send" color="#091f38" />
                      </div>
                    </div>
                    <hr class="mt-3 mb-1 horizontal dark" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Key Inventory-->
          <div class="col-lg-6">
            <div class="card h-100">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-0">Crate Key Inventory</h6>
                </div>
              </div>
              <div class="p-3 card-body">
                <ul class="list-group list-group-flush list my--3" v-if="Object.keys(items).length !== 0">
                  <li class="px-0 border-0 list-group-item">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <img src="@/assets/img/game/key-mythic.png" alt="Item" style="width: 50%; height: 100%"/>
                      </div>
                      <div class="col">
                        <p class="mb-0 text-xs font-weight-bold">Item:</p>
                        <h6 class="mb-0 text-sm">Mythic Key</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Amount:</p>
                        <h6 class="mb-0 text-sm">{{ items["7562b113-d945-4493-91f5-2c067d8fc244"]["balances"]["1"] }}</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Transfer:</p>
                        <Icon class="mt-0 h5" icon="mdi:cube-send" color="#091f38" />
                      </div>
                    </div>
                    <hr class="mt-3 mb-1 horizontal dark" />
                  </li>
                  <li class="px-0 border-0 list-group-item">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <img src="@/assets/img/game/key-cosmic.png" alt="Item" style="width: 50%; height: 100%"/>
                      </div>
                      <div class="col">
                        <p class="mb-0 text-xs font-weight-bold">Item:</p>
                        <h6 class="mb-0 text-sm">Cosmic Key</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Amount:</p>
                        <h6 class="mb-0 text-sm">{{ items["7562b113-d945-4493-91f5-2c067d8fc244"]["balances"]["2"] }}</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Transfer:</p>
                        <Icon class="mt-0 h5" icon="mdi:cube-send" color="#091f38" />
                      </div>
                    </div>
                    <hr class="mt-3 mb-1 horizontal dark" />
                  </li>
                  <li class="px-0 border-0 list-group-item">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <img src="@/assets/img/game/key-celestial.png" alt="Item" style="width: 50%; height: 100%"/>
                      </div>
                      <div class="col">
                        <p class="mb-0 text-xs font-weight-bold">Item:</p>
                        <h6 class="mb-0 text-sm">Celestial Key</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Amount:</p>
                        <h6 class="mb-0 text-sm">{{ items["7562b113-d945-4493-91f5-2c067d8fc244"]["balances"]["3"] }}</h6>
                      </div>
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Transfer:</p>
                        <Icon class="mt-0 h5" icon="mdi:cube-send" color="#091f38" />
                      </div>
                    </div>
                    <hr class="mt-3 mb-1 horizontal dark" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr class="mt-4 mb-1 horizontal dark">

          <div class="col-lg-9 mb-4 h-50">
            <img
              src="@/assets/img/loot-banner.png"
              class="align-bottom img-fluid shadow shadow-lg"
              alt="gold pass"
              style="width: 100%; height: 100% "
            />
          </div>
          <div class="col-lg-3 mb-4">
            <div class="card h-100">
              <div class="card-header pb-0 p-3">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <h6 class="mb-0">Receipts</h6>
                  </div>
                  <!--        <div class="col-6 text-end">-->
                  <!--          <vmd-button color="primary" size="sm" variant="outline"-->
                  <!--            >View All</vmd-button-->
                  <!--          >-->
                  <!--        </div>-->
                </div>
              </div>
              <div class="card-body p-3 pb-0 mb-0">
                <template v-if="receipts.length > 0">
                  <ul class="list-group" v-for="receipt in receipts">
                    <li
                      class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                    >
                      <div class="d-flex flex-column">
                        <h6 class="mb-1 text-dark font-weight-bold text-sm">
                          {{ receipt.time }}
                        </h6>
                        <span class="text-xs">#{{ receipt.id }}</span>
                      </div>
                      <div class="d-flex align-items-center text-sm">
                        ${{ receipt.amount / 100 }}
                        <a class="btn btn-link text-dark text-sm mb-0 px-0 ms-4" :href="receipt.receipt_url">
                          <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
                          PDF
                        </a>
                      </div>
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p class="text-center">No Receipts available</p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniCards from "./components/MiniCards.vue";
import { Icon } from '@iconify/vue';
import SiteHeader from "@/views/Header";
import InvoiceCard from "@/views/pages/account/components/InvoiceCard";

export default {
  name: "dashboard-default",
  components: {
    SiteHeader,
    MiniCards,
    Icon
  },
  data() {
    return {
      authUri: "https://accounts.trymetafab.com/oauth/?flow=loginConnect&chain=MATIC&ecosystem=ca62800b-ffeb-4c0f-815b-d8dbe4233170&game=b72c1549-27fe-4329-bd66-05901137add0&redirectUri=https://cubecolony.net/",
      accessToken: null,
      id: null,
      player: {username: "", wallet: ""},
      receipts: [],
      minecraftPlayerDetails: {head_2d: "https://crafatar.com/avatars/f3560c66-36bd-4bcc-93fe-08bdda1e60ca", minecraft_username: "Unknown"},
      items: {},
      currencies: {},
      cubes: "0.0",
      lootBoxes: "0"
    };
  },
  computed: {},
  methods: {},
  async mounted() {
    if (localStorage.accessToken && localStorage.id && localStorage.walletDecryptKey) {
      this.accessToken = localStorage.accessToken
      this.id = localStorage.id
      this.walletDecryptKey = localStorage.walletDecryptKey
    } else if (this.$route.hash) {
      const hashWithoutLeadingHash = this.$route.hash.replace(/^#/, "")
      const keyValuePairs = hashWithoutLeadingHash.split('&');
      keyValuePairs.forEach(keyValuePair => {
        const [key, value] = keyValuePair.split('=');
        localStorage[key] = value
      });
      this.accessToken = localStorage.accessToken
      this.id = localStorage.id
      this.walletDecryptKey = localStorage.walletDecryptKey

      // Force a full page reload
      const cleanUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      setTimeout(() => {
        window.location.replace(cleanUrl);
      }, 0);
    } else {
      alert("To access the CubeColony SMP store, please log in first. You are currently not logged in. Please close this alert to proceed to the login page.")
      window.location.replace(this.authUri)
    }

    const options = {method: 'GET', headers: {accept: 'application/json'}};
    fetch(`https://api.trymetafab.com/v1/players/${this.id}`, options)
      .then(response => response.json())
      .then(response => {
        this.player = response; console.log(response)
        const api_options = {method: 'GET', headers: {accept: 'application/json'}};
        fetch(`https://api.cubecolony.net/v1/metafab/transactions?address=${response.wallet.address}`, api_options)
          .then(response => response.json())
          .then(response => {this.receipts = response; console.log(response)})
          .catch(err => console.error(err));

        fetch(`https://api.cubecolony.net/v1/metafab/minecraft/player?id=${response.id}`, api_options)
          .then(response => response.json())
          .then(response => {this.minecraftPlayerDetails = response; console.log(response)})
          .catch(err => console.error(err));

        fetch(`https://api.cubecolony.net/v1/metafab/balances?address=${response.wallet.address}`, api_options)
          .then(response => response.json())
          .then(response => {
            this.items = response["items"];
            this.currencies = response["currencies"];
            this.cubes = response["currencies"]["92ccb307-931f-4f82-918a-966b0b184f45"]["balances"]
            this.lootBoxes = response["items"]["31a22119-db49-45a9-8829-8f4b234cb2f2"]["balances"]["1"]
            console.log(response)
          })
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));

    console.log(this.player.wallet.address)
  },
  watch: {
    accessToken(newAccessToken) {
      localStorage.accessToken = newAccessToken;
    },
    id(newId) {
      localStorage.id = newId;
    },
    walletDecryptKey(newWalletDecryptKey) {
      localStorage.walletDecryptKey = newWalletDecryptKey;
    }
  }
};
</script>
