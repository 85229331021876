<template>
  <div class="container-fluid px-0">
    <site-header :username="this.player.username" :wallet="player.wallet.address" :player="player" :minecraft="minecraftPlayerDetails" :items="lootBoxes" :currencies="cubes"></site-header>
  </div>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row text-center">
          <h4 class="text-uppercase">Survival {{ this.categoryType }}</h4>
          <div class="mt-auto mb-2">
            <span >
              <router-link to="/dashboard"><Icon class="mt-0 h2" icon="mdi:arrow-back-circle" color="#1D2435FF" /></router-link>
            </span>&nbsp;
            <p class="lead text-center text-sm">{{this.purchaseItems[0].description}}</p>

          </div>
        </div>
        <div class="row mt-4">
          <template v-for="item in purchaseItems">
            <div class="col-sm-3 col-12 mb-2 text-center mt-2">
              <div class="card bg-success-soft" style="background-image: linear-gradient(50deg,#1d2435,#010101);">
                <div class="row mt-4">
                  <div class="col-12 mt-6">
                    <img
                      :src="require('../assets/img/game/' + item.img)"
                      class="card-img-top align-bottom img-fluid"
                      alt=""
                      style="width: 30%; height: 100%"
                    />
                  </div>
                  <div class="col-sm-12 col-12 mb-2 text-center">
                    <h4 class="display-6 text-bolder text-white">{{ item.name }}</h4>
                    <p class="lead text-bold text-danger mt-0 mb-0"><del>${{item.originalPrice}}</del></p>
                    <p class="lead text-bold text-success ">${{item.discountedPrice}} <small>USD</small></p>
                    <button v-if="item.kitItems.length" class="btn" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+ item.uniqueName.replace(/\s/g, '')" aria-expanded="false" aria-controls="collapseExample">
                      Details
                    </button>
                  </div>
                </div>
                <div v-if="item.kitItems.length > 0" class="collapse" :id="item.uniqueName.replace(/\s/g, '')">
                  <div class="card card-body overflow-auto" style="background-image: linear-gradient(50deg,#1d2435,#010101);">
                    <div class="row mb-2">
                      <div class="col-12">
                        <p v-if="item.generalItems.length > 0" class="lead text-bold text-white mt-0 mb-0">General Items</p>
                        <ul v-if="item.generalItems.length > 0" class="text-xs text-start">
                          <template v-for="genItems in item.generalItems">
                            <li>{{genItems}}</li>
                          </template>
                        </ul>
                        <img
                          :src="require('../assets/img/game/' + this.categoryType + '/' + item.image)"
                          class="card-img-top align-bottom img-fluid"
                          alt=""
                          style="width: 100%; height: 40%"
                        />
                        <p class="lead text-bold text-white mt-0 mb-0">Kit Items</p>
                        <ul class="text-xs text-start">
                          <template v-for="kitItems in item.kitItems">
                            <li>{{kitItems}}</li>
                          </template>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card card-footer" style="background-image: linear-gradient(50deg,#1d2435,#010101);">
                  <button type="button" class="btn btn-success btn-lg btn-block" v-on:click="purchaseItem(item)" :disabled="loadingButton">Purchase</button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniCards from "./components/MiniCards.vue";
import { Icon } from "@iconify/vue";
import purchaseItems from "./purchaseItems";
import SiteHeader from "@/views/Header";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: "category",
  components: {
    MiniCards,
    Icon,
    SiteHeader,
    StripeCheckout,
  },
  data() {
    return {
      authUri: "https://accounts.trymetafab.com/oauth/?flow=loginConnect&chain=MATIC&ecosystem=ca62800b-ffeb-4c0f-815b-d8dbe4233170&game=b72c1549-27fe-4329-bd66-05901137add0&redirectUri=https://cubecolony.net/",
      accessToken: null,
      id: null,
      player: {username: "", wallet: ""},
      categoryType: "ranks",
      purchaseItems: purchaseItems["ranks"],
      loading: false,
      loadingButton: false,
      dataLoaded: false,
      baseURL: "https://cubecolony.net/",
      successURL: "https://cubecolony.net/success",
      minecraftPlayerDetails: {head_2d: "https://crafatar.com/avatars/f3560c66-36bd-4bcc-93fe-08bdda1e60ca", minecraft_username: "Unknown"},
      items: [],
      currencies: [],
      cubes: "0.0",
      lootBoxes: "0"
    };
  },
  computed: {},
  methods: {
    async purchaseItem(item) {
      this.loadingButton = true;
      // Test Key: 'pk_test_51KFoCoKGtL2OhGM1wL83V6yYCerytxFhIR2H5w70OgQkAAFlajkfTiJ73vddpHUUjZB4Ir9XKtEcJTfIU8pBtxWK00ZEQNiAAE';
      const stripePublicKey = 'pk_live_51KFoCoKGtL2OhGM1KPiG3YUWpYoflmjAKyXYotGRFGr0qhj9HbJ9IdpcVrINouNdZStpEL69b06sLIM6yiW6hU1t00Z7cECu4Y'
      const stripe = await loadStripe(stripePublicKey);

      if (!stripe) {
        console.error('Failed to initialize Stripe.js');
        return;
      }

      const successLink = this.successURL + '?success=true' +
        '&wallet=' + (this.player?.wallet?.address || '') +
        '&username=' + (this.player?.username || '') +
        '&itemId=' + (item?.id || '') +
        '&type=' + (this.categoryType || '') +
        '&itemName=' + (item?.name || '')

      const cancelLink = this.baseURL +
        '?wallet=' + (this.player?.wallet?.address || '') +
        '&username=' + (this.player?.username || '') +
        '&itemId=' + (item?.id || '') +
        '&type=' + (this.categoryType || '') +
        '&itemName=' + (item?.name || '')

      const result = await stripe.redirectToCheckout({
        lineItems: [{ price: item.priceId, quantity: 1 }],
        mode: 'payment',
        successUrl: successLink,
        cancelUrl: cancelLink,
        clientReferenceId: this.player.wallet.address
      });

      if (result.error) {
        console.error('Error during redirectToCheckout:', result.error.message);
      }
    }
  },
  async mounted() {
    if (this.$route.params) {
      this.categoryType = this.$route.params['type']
      if (purchaseItems.hasOwnProperty(this.$route.params['type'])) {
        this.purchaseItems = purchaseItems[this.$route.params['type']]
      } else {
        console.error('Invalid type key:', this.$route.params['type'])
      }
    }
    if (localStorage.accessToken && localStorage.id && localStorage.walletDecryptKey) {
      this.accessToken = localStorage.accessToken
      this.id = localStorage.id
      this.walletDecryptKey = localStorage.walletDecryptKey
    } else if (this.$route.hash) {
      const hashWithoutLeadingHash = this.$route.hash.replace(/^#/, "")
      const keyValuePairs = hashWithoutLeadingHash.split('&');
      keyValuePairs.forEach(keyValuePair => {
        const [key, value] = keyValuePair.split('=');
        localStorage[key] = value
      });
      this.accessToken = localStorage.accessToken
      this.id = localStorage.id
      this.walletDecryptKey = localStorage.walletDecryptKey

      // Force a full page reload
      const cleanUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      setTimeout(() => {
        window.location.replace(cleanUrl);
      }, 0);
    } else {
      alert("To access the CubeColony SMP store, please log in first. You are currently not logged in. Please close this alert to proceed to the login page.")
      window.location.replace(this.authUri)
    }

    const options = {method: 'GET', headers: {accept: 'application/json'}};
    fetch(`https://api.trymetafab.com/v1/players/${this.id}`, options)
      .then(response => response.json())
      .then(response => {
        this.player = response;
        console.log(response)
        const api_options = {method: 'GET', headers: {accept: 'application/json'}};
        fetch(`https://api.cubecolony.net/v1/metafab/minecraft/player?id=${response.id}`, api_options)
          .then(response => response.json())
          .then(response => {this.minecraftPlayerDetails = response; console.log(response)})
          .catch(err => console.error(err));

        fetch(`https://api.cubecolony.net/v1/metafab/balances?address=${response.wallet.address}`, api_options)
          .then(response => response.json())
          .then(response => {this.items = response; console.log(response)})
          .catch(err => console.error(err));

        fetch(`https://api.cubecolony.net/v1/metafab/balances?address=${response.wallet.address}`, api_options)
          .then(response => response.json())
          .then(response => {
            this.items = response["items"];
            this.currencies = response["currencies"];
            this.cubes = response["currencies"]["92ccb307-931f-4f82-918a-966b0b184f45"]["balances"]
            this.lootBoxes = response["items"]["31a22119-db49-45a9-8829-8f4b234cb2f2"]["balances"]["1"]
            console.log(response)
          })
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
    this.dataLoaded = true
  },
  watch: {
    accessToken(newAccessToken) {
      localStorage.accessToken = newAccessToken;
    },
    id(newId) {
      localStorage.id = newId;
    },
    walletDecryptKey(newWalletDecryptKey) {
      localStorage.walletDecryptKey = newWalletDecryptKey;
    }
  }
};
</script>
